const languageCode = [
  {
    language: "Abkhazian",
    isoCode: "ab",
  },
  {
    language: "Afar",
    isoCode: "aa",
  },
  {
    language: "Afrikaans",
    isoCode: "af",
  },
  {
    language: "Akan",
    isoCode: "ak",
  },
  {
    language: "Albanian",
    isoCode: "sq",
  },
  {
    language: "Amharic",
    isoCode: "am",
  },
  {
    language: "Arabic",
    isoCode: "ar",
  },
  {
    language: "Aragonese",
    isoCode: "an",
  },
  {
    language: "Armenian",
    isoCode: "hy",
  },
  {
    language: "Assamese",
    isoCode: "as",
  },
  {
    language: "Avaric",
    isoCode: "av",
  },
  {
    language: "Avestan",
    isoCode: "ae",
  },
  {
    language: "Aymara",
    isoCode: "ay",
  },
  {
    language: "Azerbaijani",
    isoCode: "az",
  },
  {
    language: "Bambara",
    isoCode: "bm",
  },
  {
    language: "Bashkir",
    isoCode: "ba",
  },
  {
    language: "Basque",
    isoCode: "eu",
  },
  {
    language: "Belarusian",
    isoCode: "be",
  },
  {
    language: "Bengali (Bangla)",
    isoCode: "bn",
  },
  {
    language: "Bihari",
    isoCode: "bh",
  },
  {
    language: "Bislama",
    isoCode: "bi",
  },
  {
    language: "Bosnian",
    isoCode: "bs",
  },
  {
    language: "Breton",
    isoCode: "br",
  },
  {
    language: "Bulgarian",
    isoCode: "bg",
  },
  {
    language: "Burmese",
    isoCode: "my",
  },
  {
    language: "Catalan",
    isoCode: "ca",
  },
  {
    language: "Chamorro",
    isoCode: "ch",
  },
  {
    language: "Chechen",
    isoCode: "ce",
  },
  {
    language: "Chichewa, Chewa, Nyanja",
    isoCode: "ny",
  },
  {
    language: "Chinese",
    isoCode: "zh",
  },
  {
    language: "Chinese (Simplified)",
    isoCode: "zh-Hans",
  },
  {
    language: "Chinese (Traditional)",
    isoCode: "zh-Hant",
  },
  {
    language: "Chuvash",
    isoCode: "cv",
  },
  {
    language: "Cornish",
    isoCode: "kw",
  },
  {
    language: "Corsican",
    isoCode: "co",
  },
  {
    language: "Cree",
    isoCode: "cr",
  },
  {
    language: "Croatian",
    isoCode: "hr",
  },
  {
    language: "Czech",
    isoCode: "cs",
  },
  {
    language: "Danish",
    isoCode: "da",
  },
  {
    language: "Divehi, Dhivehi, Maldivian",
    isoCode: "dv",
  },
  {
    language: "Dutch",
    isoCode: "nl",
  },
  {
    language: "Dzongkha",
    isoCode: "dz",
  },
  {
    language: "English",
    isoCode: "en",
  },
  {
    language: "Esperanto",
    isoCode: "eo",
  },
  {
    language: "Estonian",
    isoCode: "et",
  },
  {
    language: "Ewe",
    isoCode: "ee",
  },
  {
    language: "Faroese",
    isoCode: "fo",
  },
  {
    language: "Fijian",
    isoCode: "fj",
  },
  {
    language: "Finnish",
    isoCode: "fi",
  },
  {
    language: "French",
    isoCode: "fr",
  },
  {
    language: "Fula, Fulah, Pulaar, Pular",
    isoCode: "ff",
  },
  {
    language: "Galician",
    isoCode: "gl",
  },
  {
    language: "Gaelic (Scottish)",
    isoCode: "gd",
  },
  {
    language: "Gaelic (Manx)",
    isoCode: "gv",
  },
  {
    language: "Georgian",
    isoCode: "ka",
  },
  {
    language: "German",
    isoCode: "de",
  },
  {
    language: "Greek",
    isoCode: "el",
  },
  {
    language: "Greenlandic",
    isoCode: "kl",
  },
  {
    language: "Guarani",
    isoCode: "gn",
  },
  {
    language: "Gujarati",
    isoCode: "gu",
  },
  {
    language: "Haitian Creole",
    isoCode: "ht",
  },
  {
    language: "Hausa",
    isoCode: "ha",
  },
  {
    language: "Hebrew",
    isoCode: "he",
  },
  {
    language: "Herero",
    isoCode: "hz",
  },
  {
    language: "Hindi",
    isoCode: "hi",
  },
  {
    language: "Hiri Motu",
    isoCode: "ho",
  },
  {
    language: "Hungarian",
    isoCode: "hu",
  },
  {
    language: "Icelandic",
    isoCode: "is",
  },
  {
    language: "Ido",
    isoCode: "io",
  },
  {
    language: "Igbo",
    isoCode: "ig",
  },
  {
    language: "Indonesian",
    isoCode: "id, in",
  },
  {
    language: "Interlingua",
    isoCode: "ia",
  },
  {
    language: "Interlingue",
    isoCode: "ie",
  },
  {
    language: "Inuktitut",
    isoCode: "iu",
  },
  {
    language: "Inupiak",
    isoCode: "ik",
  },
  {
    language: "Irish",
    isoCode: "ga",
  },
  {
    language: "Italian",
    isoCode: "it",
  },
  {
    language: "Japanese",
    isoCode: "ja",
  },
  {
    language: "Javanese",
    isoCode: "jv",
  },
  {
    language: "Kalaallisut, Greenlandic",
    isoCode: "kl",
  },
  {
    language: "Kannada",
    isoCode: "kn",
  },
  {
    language: "Kanuri",
    isoCode: "kr",
  },
  {
    language: "Kashmiri",
    isoCode: "ks",
  },
  {
    language: "Kazakh",
    isoCode: "kk",
  },
  {
    language: "Khmer",
    isoCode: "km",
  },
  {
    language: "Kikuyu",
    isoCode: "ki",
  },
  {
    language: "Kinyarwanda (Rwanda)",
    isoCode: "rw",
  },
  {
    language: "Kirundi",
    isoCode: "rn",
  },
  {
    language: "Kyrgyz",
    isoCode: "ky",
  },
  {
    language: "Komi",
    isoCode: "kv",
  },
  {
    language: "Kongo",
    isoCode: "kg",
  },
  {
    language: "Korean",
    isoCode: "ko",
  },
  {
    language: "Kurdish",
    isoCode: "ku",
  },
  {
    language: "Kwanyama",
    isoCode: "kj",
  },
  {
    language: "Lao",
    isoCode: "lo",
  },
  {
    language: "Latin",
    isoCode: "la",
  },
  {
    language: "Latvian (Lettish)",
    isoCode: "lv",
  },
  {
    language: "Limburgish ( Limburger)",
    isoCode: "li",
  },
  {
    language: "Lingala",
    isoCode: "ln",
  },
  {
    language: "Lithuanian",
    isoCode: "lt",
  },
  {
    language: "Luga-Katanga",
    isoCode: "lu",
  },
  {
    language: "Luganda, Ganda",
    isoCode: "lg",
  },
  {
    language: "Luxembourgish",
    isoCode: "lb",
  },
  {
    language: "Manx",
    isoCode: "gv",
  },
  {
    language: "Macedonian",
    isoCode: "mk",
  },
  {
    language: "Malagasy",
    isoCode: "mg",
  },
  {
    language: "Malay",
    isoCode: "ms",
  },
  {
    language: "Malayalam",
    isoCode: "ml",
  },
  {
    language: "Maltese",
    isoCode: "mt",
  },
  {
    language: "Maori",
    isoCode: "mi",
  },
  {
    language: "Marathi",
    isoCode: "mr",
  },
  {
    language: "Marshallese",
    isoCode: "mh",
  },
  {
    language: "Moldavian",
    isoCode: "mo",
  },
  {
    language: "Mongolian",
    isoCode: "mn",
  },
  {
    language: "Nauru",
    isoCode: "na",
  },
  {
    language: "Navajo",
    isoCode: "nv",
  },
  {
    language: "Ndonga",
    isoCode: "ng",
  },
  {
    language: "Northern Ndebele",
    isoCode: "nd",
  },
  {
    language: "Nepali",
    isoCode: "ne",
  },
  {
    language: "Norwegian",
    isoCode: "no",
  },
  {
    language: "Norwegian bokmål",
    isoCode: "nb",
  },
  {
    language: "Norwegian nynorsk",
    isoCode: "nn",
  },
  {
    language: "Nuosu",
    isoCode: "ii",
  },
  {
    language: "Occitan",
    isoCode: "oc",
  },
  {
    language: "Ojibwe",
    isoCode: "oj",
  },
  {
    language: "Old Church Slavonic, Old Bulgarian",
    isoCode: "cu",
  },
  {
    language: "Oriya",
    isoCode: "or",
  },
  {
    language: "Oromo (Afaan Oromo)",
    isoCode: "om",
  },
  {
    language: "Ossetian",
    isoCode: "os",
  },
  {
    language: "Pāli",
    isoCode: "pi",
  },
  {
    language: "Pashto, Pushto",
    isoCode: "ps",
  },
  {
    language: "Persian (Farsi)",
    isoCode: "fa",
  },
  {
    language: "Polish",
    isoCode: "pl",
  },
  {
    language: "Portuguese",
    isoCode: "pt",
  },
  {
    language: "Punjabi (Eastern)",
    isoCode: "pa",
  },
  {
    language: "Quechua",
    isoCode: "qu",
  },
  {
    language: "Romansh",
    isoCode: "rm",
  },
  {
    language: "Romanian",
    isoCode: "ro",
  },
  {
    language: "Russian",
    isoCode: "ru",
  },
  {
    language: "Sami",
    isoCode: "se",
  },
  {
    language: "Samoan",
    isoCode: "sm",
  },
  {
    language: "Sango",
    isoCode: "sg",
  },
  {
    language: "Sanskrit",
    isoCode: "sa",
  },
  {
    language: "Serbian",
    isoCode: "sr",
  },
  {
    language: "Serbo-Croatian",
    isoCode: "sh",
  },
  {
    language: "Sesotho",
    isoCode: "st",
  },
  {
    language: "Setswana",
    isoCode: "tn",
  },
  {
    language: "Shona",
    isoCode: "sn",
  },
  {
    language: "Sichuan Yi",
    isoCode: "ii",
  },
  {
    language: "Sindhi",
    isoCode: "sd",
  },
  {
    language: "Sinhalese",
    isoCode: "si",
  },
  {
    language: "Siswati",
    isoCode: "ss",
  },
  {
    language: "Slovak",
    isoCode: "sk",
  },
  {
    language: "Slovenian",
    isoCode: "sl",
  },
  {
    language: "Somali",
    isoCode: "so",
  },
  {
    language: "Southern Ndebele",
    isoCode: "nr",
  },
  {
    language: "Spanish",
    isoCode: "es",
  },
  {
    language: "Sundanese",
    isoCode: "su",
  },
  {
    language: "Swahili (Kiswahili)",
    isoCode: "sw",
  },
  {
    language: "Swati",
    isoCode: "ss",
  },
  {
    language: "Swedish",
    isoCode: "sv",
  },
  {
    language: "Tagalog",
    isoCode: "tl",
  },
  {
    language: "Tahitian",
    isoCode: "ty",
  },
  {
    language: "Tajik",
    isoCode: "tg",
  },
  {
    language: "Tamil",
    isoCode: "ta",
  },
  {
    language: "Tatar",
    isoCode: "tt",
  },
  {
    language: "Telugu",
    isoCode: "te",
  },
  {
    language: "Thai",
    isoCode: "th",
  },
  {
    language: "Tibetan",
    isoCode: "bo",
  },
  {
    language: "Tigrinya",
    isoCode: "ti",
  },
  {
    language: "Tonga",
    isoCode: "to",
  },
  {
    language: "Tsonga",
    isoCode: "ts",
  },
  {
    language: "Turkish",
    isoCode: "tr",
  },
  {
    language: "Turkmen",
    isoCode: "tk",
  },
  {
    language: "Twi",
    isoCode: "tw",
  },
  {
    language: "Uyghur",
    isoCode: "ug",
  },
  {
    language: "Ukrainian",
    isoCode: "uk",
  },
  {
    language: "Urdu",
    isoCode: "ur",
  },
  {
    language: "Uzbek",
    isoCode: "uz",
  },
  {
    language: "Venda",
    isoCode: "ve",
  },
  {
    language: "Vietnamese",
    isoCode: "vi",
  },
  {
    language: "Volapük",
    isoCode: "vo",
  },
  {
    language: "Wallon",
    isoCode: "wa",
  },
  {
    language: "Welsh",
    isoCode: "cy",
  },
  {
    language: "Wolof",
    isoCode: "wo",
  },
  {
    language: "Western Frisian",
    isoCode: "fy",
  },
  {
    language: "Xhosa",
    isoCode: "xh",
  },
  {
    language: "Yiddish",
    isoCode: "yi, ji",
  },
  {
    language: "Yoruba",
    isoCode: "yo",
  },
  {
    language: "Zhuang, Chuang",
    isoCode: "za",
  },
  {
    language: "Zulu",
    isoCode: "zu",
  },
];

export default languageCode;
