// preview page client api here
import clientAPI from "@utils/axios/client-api";
import qs from "querystringify";

const createNote = ({ postData }) => {
  const { videoId } = postData;
  return clientAPI
    .post(`/api/v1/note/video/${videoId}/note`, postData)
    .then((res) => res.data);
};

const createQuestion = ({ postData }) => {
  const { videoId } = postData;
  return clientAPI
    .post(`/api/v1/forum/video/${videoId}/question`, postData)
    .then((res) => res.data);
};

const getNoteList = (postData) => {
  const { videoId, startTime, endTime, pageNum = 0 } = postData;
  const query = qs.stringify({
    pageNum,
    startTime,
    endTime,
  });
  return clientAPI
    .get(
      `/api/v1/note/video/${videoId}/note?pageSize=7&sortKey=marker_time&sortOrder=-1&${query}`,
    )
    .then((res) => res.data);
};

const getNoteMarkerTimeList = (postData) => {
  const { videoId } = postData;

  return clientAPI
    .get(`/api/v1/note/video/${videoId}/marker_time`)
    .then((res) => res.data);
};

const getQuestionList = (videoId) =>
  clientAPI
    .get(
      `/api/v1/forum/video/${videoId}/question?pageNum=0&sortOrder=1&pageSize=200`,
    )
    .then((res) => res.data);

const deleteNote = (videoId, noteId) =>
  clientAPI
    .delete(`/api/v1/note/video/${videoId}/note/${noteId}`)
    .then((res) => res.data);

const saveNotePoint = (videoId, noteId, postData) =>
  clientAPI
    .put(`/api/v1/note/video/${videoId}/note/${noteId}`, postData)
    .then((res) => res.data);

const addReplyPoint = (postData) => {
  const { videoId, questionId } = postData;
  return clientAPI
    .post(
      `/api/v1/forum/video/${videoId}/question/${questionId}/reply`,
      postData,
    )
    .then((res) => res.data);
};

const getReplyListPoint = (postData) => {
  const { videoId, questionId } = postData;
  return clientAPI
    .get(
      `/api/v1/forum/video/${videoId}/question/${questionId}/reply?sortKey=create_time&sortOrder=-1&pageSize=200`,
    )
    .then((res) => res.data);
};

const updateQuestionPoint = (postData) => {
  const { videoId, questionId } = postData;
  return clientAPI
    .put(`/api/v1/forum/video/${videoId}/question/${questionId}`, postData)
    .then((res) => res.data);
};

const deleteQuestionPoint = (postData) => {
  const { videoId, questionId } = postData;
  return clientAPI
    .delete(`/api/v1/forum/video/${videoId}/question/${questionId}`)
    .then((res) => res.data);
};

const postLikePoint = (postData) => {
  const { itemType, status, itemId } = postData;
  return clientAPI
    .post(
      `/api/v1/forum/like/${itemType}?status=${status}&itemId=${itemId}`,
      postData,
    )
    .then((res) => res.data);
};

const updateReplyPoint = (postData) => {
  const { videoId, replyId, questionId } = postData;
  return clientAPI
    .put(
      `/api/v1/forum/video/${videoId}/question/${questionId}/reply/${replyId}`,
      postData,
    )
    .then((res) => res.data);
};

const deleteReplyPoint = (postData) => {
  const { videoId, questionId, replyId } = postData;
  return clientAPI
    .delete(
      `/api/v1/forum/video/${videoId}/question/${questionId}/reply/${replyId}`,
    )
    .then((res) => res.data);
};

const postReplyHelpfulPoint = (postData) => {
  const { videoId, questionId, replyId, status } = postData;
  return clientAPI
    .post(
      `/api/v1/forum/video/${videoId}/question/${questionId}/reply/${replyId}/helpful?status=${status}`,
      {},
    )
    .then((res) => res.data);
};

const progressRecord = (postData) => {
  const { itemId, startIndex, endIndex, itemType } = postData;
  return clientAPI
    .post(
      `/api/v1/learning-system/${itemType}/${itemId}/action?startIndex=${startIndex}&endIndex=${endIndex}`,
      {},
    )
    .then((res) => res.data);
};

const getLearningVideoInfo = (videoId) =>
  clientAPI
    .get(`/api/v1/learning-system/video/${videoId}`)
    .then((res) => res.data);

const getLearningCaptionContent = (videoId) =>
  clientAPI
    .get(`/api/v1/learning-system/video/${videoId}/caption`)
    .then((res) => res.data);

const getPreviewCaptionContent = (videoId) => {
  const query = qs.stringify({
    videoId,
  });

  return clientAPI
    .get(`/api/v1/catalog/preview/video_caption?${query}`)
    .then((res) => res.data);
};

export const getCourseCaptionLanguage = ({ courseId }) => {
  const query = qs.stringify({
    courseId,
  });
  return clientAPI
    .get(`/api/v1/catalog/course/caption/language?${query}`)
    .then((res) => res.data);
};

const recordLastWatchPoint = (postData) => {
  const { courseId, learningType: type, learningItemId: contentId } = postData;
  const query = qs.stringify({
    courseId,
    type,
    contentId,
  });
  return clientAPI
    .post(`/api/v1/learning-system/latest/watch?${query}`)
    .then((res) => res.data);
};

const getLastWatchPoint = () =>
  clientAPI
    .get(`/api/v1/learning-system/course/latest/watch`)
    .then((res) => res.data);

const searchDiscussionPoint = (postData) => {
  const { videoId, keyword } = postData;
  return clientAPI
    .get(
      `/api/v1/forum/search/video/${videoId}/discussion?keyWord=${keyword}&sortOrder=-1&pageSize=200`,
    )
    .then((res) => res.data);
};

const getRelatedInfoPoint = (postData) => {
  const { videoId, courseId } = postData;
  return clientAPI
    .get(
      `/api/v1/search/recommendation/related_course?videoId=${videoId}&courseId=${courseId}`,
    )
    .then((res) => res.data);
};

const getDocumentInfoPoint = (docId) =>
  clientAPI.get(`/api/v1/learning-system/doc/${docId}`).then((res) => res.data);

const getLatestWatchRecordPoint = (postData) => {
  const { courseId, contentType, contentId } = postData;
  return clientAPI
    .get(
      `/api/v1/learning-system/latest/watch/record?courseId=${courseId}&contentType=${contentType}&contentId=${contentId}`,
    )
    .then((res) => res.data);
};

const getH5InfoPoint = (h5Id) =>
  clientAPI.get(`/api/v1/learning-system/h5/${h5Id}`).then((res) => res.data);

/**
 * @api /api/v1/learning-system/doc/{docId}
 * @apiDescription get doc info
 */

const getDocumentWatchDuration = ({ documentId }) =>
  clientAPI
    .get(`/api/v1/learning-system/doc/watch/${documentId}`)
    .then((response) => response.data);

/**
 * @api /api/v1/forum/video/{videoId}/question/{questionId}
 * @apiDescription get discussion
 */

const getDiscussionPoint = ({ videoId, questionId }) =>
  clientAPI
    .get(`/api/v1/forum/video/${videoId}/question/${questionId}`)
    .then((response) => response.data);

const searchNotePoint = (postData) => {
  const { videoId, keyword } = postData;
  return clientAPI
    .get(`/api/v1/note/video/${videoId}/note?keyWord=${keyword}&sortOrder=-1`)
    .then((res) => res.data);
};
export {
  addReplyPoint,
  createNote,
  createQuestion,
  deleteNote,
  deleteQuestionPoint,
  deleteReplyPoint,
  getDiscussionPoint,
  getDocumentInfoPoint,
  getDocumentWatchDuration,
  getH5InfoPoint,
  getLastWatchPoint,
  getLatestWatchRecordPoint,
  getLearningCaptionContent,
  getLearningVideoInfo,
  getNoteList,
  getNoteMarkerTimeList,
  getPreviewCaptionContent,
  getQuestionList,
  getRelatedInfoPoint,
  getReplyListPoint,
  postLikePoint,
  postReplyHelpfulPoint,
  progressRecord,
  recordLastWatchPoint,
  saveNotePoint,
  searchDiscussionPoint,
  searchNotePoint,
  updateQuestionPoint,
  updateReplyPoint,
};
