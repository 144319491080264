import numeral from "numeral";

export const formatNum = (value) => numeral(value).format("0,0");

export const numberAbbr = (value, format = "0a") =>
  numeral(value).format(format);

export const percentNum = (value) => numeral(value).format("0.0%");

export const formatTwoDigit = (value) => numeral(value).format("00");

export const dashboardNum = (value) =>
  value >= 1000000 ? numberAbbr(value, "0.00000a") : formatNum(value);

export const padTwoDigit = (num) => {
  if (num === 0) {
    return "0";
  }
  return numeral(num).format("00");
};
