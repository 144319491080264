import * as dayjs from "dayjs";
import * as duration from "dayjs/plugin/duration";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";

import("dayjs/locale/zh-tw");
import("dayjs/locale/zh-cn");

dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const formatToSeconds = (markerTime) => {
  const durationSplit = markerTime.split(":");
  const [hours, minutes, seconds] = durationSplit;
  if (durationSplit.length === 3) {
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
  }
  if (durationSplit.length === 2) {
    return Number(hours) * 60 + Number(minutes);
  }
  return 0;
};

export const translateMomentLocale = (localeValue) => {
  switch (localeValue) {
    case "zh-Hant":
      return "zh-tw";
    case "zh-Hans":
      return "zh-cn";
    default:
      return "en";
  }
};

export const dateFormat = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const dateISOFormat = (date) => {
  return new Date(dayjs(date).format("YYYY-MM-DD")).toISOString();
};

export const dateFormatValue = (date) => {
  return dayjs(date, "YYYY-MM-DD").valueOf;
};

export default dayjs;
