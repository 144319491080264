import isoLanguageList from "@constants/language-code";
import { filter } from "ramda";

const isIsoTarget = (languageCode) => {
  return (element) => {
    return (
      element.isoCode
        .toLowerCase()
        .replace(/ /g, "")
        .split(",")
        .indexOf(languageCode?.toLowerCase()) >= 0
    );
  };
};

export const transferLanguage = (languageCode) => {
  if (languageCode) {
    const filterRes = filter(isIsoTarget(languageCode), isoLanguageList);
    const res = filterRes.length ? filterRes[0].language : "-";
    return res;
  }
  return "-";
};

export const existedLanguage = (languageCode) => {
  const filterRes = filter(isIsoTarget(languageCode), isoLanguageList);
  return filterRes.length > 0;
};

export const mapRoleToIntlKey = {
  admin: "console.role.admin",
  provider: "console.role.provider",
  editor: "console.role.editor",
  instructor: "console.role.instructor",
  learner: "console.role.learner",
};

export const mapDashboardToIntlKey = {
  login: "dashboard.dataKey.login",
  activelyLearner: "dashboard.dataKey.activelyLearner",
  registered: "dashboard.dataKey.registered",
  videoContent: "dashboard.dataKey.videoContent",
  documentContent: "dashboard.dataKey.documentContent",
  assessmentContent: "dashboard.dataKey.assessmentContent",
  h5Content: "dashboard.dataKey.h5Content",
  askedQuestion: "dashboard.dataKey.askedQuestion",
  repliedQuestion: "dashboard.dataKey.repliedQuestion",
  approval: "dashboard.dataKey.approval",
  helpful: "dashboard.dataKey.helpful",
  usedImportantMarker: "dashboard.dataKey.usedImportantMarker",
  madeNote: "dashboard.dataKey.madeNote",
  usedSectionMarker: "dashboard.dataKey.usedSectionMarker",
  usedQuestionMarkerOnPlay: "dashboard.dataKey.usedQuestionMarkerOnPlay",
  usedQuestionMarkerOnMiniPlay:
    "dashboard.dataKey.usedQuestionMarkerOnMiniPlay",
  learnerView: "dashboard.dataKey.learnerView",
  completion: "dashboard.dataKey.completion",
  visitorView: "dashboard.dataKey.visitorView",
  enrolled: "dashboard.dataKey.enrolled",
  totalEnrollmentUser: "dashboard.dataKey.totalEnrollmentUser",
  completeUserCount: "dashboard.dataKey.completeUserCount",
  progressUserCount: "dashboard.dataKey.progressUserCount",
  notStartUserCount: "dashboard.dataKey.notStartUserCount",
  likeCount: "dashboard.dataKey.likeCount",
  shareCount: "dashboard.dataKey.shareCount",
  videoBar: "dashboard.dataKey.videoBar",
  documentBar: "dashboard.dataKey.documentBar",
  assessmentBar: "dashboard.dataKey.assessmentBar",
  h5Bar: "dashboard.dataKey.h5Bar",
};
