export const currencyEnum = {
  FREE: 0,
  SGD: 1,
  USD: 2,
  TWD: 3,
  CNY: 4,
  EUR: 5,
  JPY: 6,

  0: "FREE",
  1: "SGD",
  2: "USD",
  3: "TWD",
  4: "CNY",
  5: "EUR",
  6: "JPY",
};
