import { getCaptionContent } from "@utils/apiServices/caption";
import dayjs, { formatToSeconds } from "@utils/dayjs-utils";
import { transferLanguage } from "@utils/language-transfer";
import { ascend, filter, findIndex, prop, propEq, sortWith } from "ramda";

import {
  getLearningCaptionContent,
  getLearningVideoInfo,
} from "./apiServices/preview";

/**
 * allowExceedLimit
 * Not limited by the time between the beginning and the end of the section.
 */

const allowLimitExceed = false;

const isMatchLimit = ({
  targetTime,
  sectionMaxLimit,
  sectionMinLimit,
  isLastSection,
}) => {
  const isFirstSection = sectionMinLimit === 0;
  if (isFirstSection) {
    return allowLimitExceed
      ? targetTime < sectionMaxLimit
      : targetTime < sectionMaxLimit && targetTime >= sectionMinLimit;
  }
  if (isLastSection) {
    return allowLimitExceed
      ? targetTime >= sectionMinLimit
      : targetTime <= sectionMaxLimit && targetTime >= sectionMinLimit;
  }
  return targetTime < sectionMaxLimit && targetTime >= sectionMinLimit;
};

const getSectionLimit = ({
  index,
  sectionMarkerList = [],
  playedSeconds,
  isLastSection,
}) => {
  let maxTemp = 0;
  let minTemp = 0;
  sectionMarkerList.forEach((item, flag) => {
    if (flag <= index) maxTemp += item.widthRatio;
  });
  sectionMarkerList.forEach((item, flag) => {
    if (flag <= index - 1) minTemp += item.widthRatio;
  });
  const sectionMinLimit = parseInt((minTemp * playedSeconds) / 100, 10);
  const maxLimit = (maxTemp * playedSeconds) / 100;
  const sectionMaxLimit = isLastSection
    ? parseInt(Math.round(maxLimit), 10) + 1
    : parseInt(maxLimit, 10);

  return {
    sectionMinLimit,
    sectionMaxLimit,
  };
};

export const calcLeft = (markerTime, totalDuration, videoWidth, maxLeft) => {
  let left =
    (formatToSeconds(markerTime) /
      parseInt(dayjs.duration(totalDuration * 1000).asSeconds(), 10)) *
    videoWidth;

  if (left >= videoWidth) {
    left = maxLeft;
  }
  return left;
};

export const leftPercent = (markerTime, totalDuration) => {
  return (
    (formatToSeconds(markerTime) /
      parseInt(dayjs.duration(totalDuration * 1000).asSeconds(), 10)) *
    100
  );
};

export const calcFiveSecondsWidth = (videoWidth, totalDuration) =>
  (videoWidth / dayjs.duration(totalDuration * 1000).asSeconds()) * 5;

export const transferDurationMilliseconds = (duration) => {
  return formatToSeconds(duration) * 1000;
};

export const transferSeekRatio = (currentTime, totalDuration) => {
  if (currentTime === 0 || totalDuration === 0) {
    return 0;
  }
  if (currentTime > totalDuration) {
    console.error("transferSeekRatio currentTime > totalDuration");
  }
  const total = Math.floor(parseFloat(totalDuration));
  const result = currentTime / total;
  return result;
};

export const matchRangeFunction = (markerTime, start, end) =>
  markerTime >= start && markerTime < end;

export const getVideoInfo = async (learningItemId) => {
  return getLearningVideoInfo(learningItemId);
};

const getLocalStorageLanguageSetting = ({
  setCaptionOn,
  setCaptionLanguage,
  captionInfoList = [],
}) => {
  const showCaption = JSON.parse(localStorage.getItem("showCaption"));
  const lastCaptionLanguage = JSON.parse(
    localStorage.getItem("lastCaptionLanguage"),
  );
  if (showCaption === true) {
    setCaptionOn(true);
  } else if (showCaption === false) {
    setCaptionOn(false);
  }
  if (
    lastCaptionLanguage &&
    captionInfoList.findIndex(
      (caption) => caption.language === lastCaptionLanguage,
    ) !== -1
  ) {
    setCaptionLanguage(lastCaptionLanguage);
  }
};

export const getCaption = async ({
  learningItemId,
  userLanguage,
  setRenderCaptionList,
  setCaptionLanguageOptions,
  setCaptionOn,
  setCaptionLanguage,
  getCaptionApi,
}) => {
  let captionInfoList = [];
  if (getCaptionApi) {
    const { data } = await getCaptionApi(learningItemId);
    captionInfoList = data.captionList || data;
  } else {
    const { data } = await getLearningCaptionContent(learningItemId);
    captionInfoList = data.captionList;
  }
  if (captionInfoList?.length) {
    const tempCaptionOption = captionInfoList.map((item, index) => {
      const isDefaultLang = item.flag === "true";
      if (isDefaultLang) setCaptionLanguage(item.language); // provider default language
      const labelRender = `${transferLanguage(item.language)}${
        isDefaultLang ? "(Default)" : ""
      }`;
      return {
        value: item.language,
        label: labelRender,
        index,
      };
    });
    if (captionInfoList.every((item) => item.flag === "false")) {
      setCaptionLanguage(captionInfoList[0].language);
    }

    captionInfoList.forEach(async (item) => {
      if (!item.captionUrl) {
        setRenderCaptionList((prev) => [
          ...prev,
          {
            language: item.language,
            caption: [],
          },
        ]);
      }
      const { data: captionContent } = await getCaptionContent(item.captionUrl);
      setRenderCaptionList((prev) => [
        ...prev,
        {
          language: item.language,
          caption: captionContent,
        },
      ]);
    });

    setCaptionLanguageOptions(tempCaptionOption);
    if (
      userLanguage &&
      captionInfoList.findIndex(
        (caption) => caption.language === userLanguage,
      ) !== -1
    ) {
      setCaptionLanguage(userLanguage);
    }
    getLocalStorageLanguageSetting({
      setCaptionOn,
      setCaptionLanguage,
      captionInfoList,
    });
  } else {
    setCaptionLanguageOptions([]);
    setRenderCaptionList([]);
  }
};

export const matchNoteRangeFunction = (markerTime, start, end) => {
  const transferToFloorMillionSeconds = (time) =>
    Math.floor(time / 1000) * 1000;
  return (
    markerTime >= transferToFloorMillionSeconds(start) &&
    markerTime < transferToFloorMillionSeconds(end)
  );
};

const findSectionRelevantCaption = (
  index,
  renderSectionMarker,
  viewCaptionList,
  playedSeconds,
) => {
  const res = [];
  const isLastSection = renderSectionMarker.length - 1 === index;
  const { sectionMaxLimit, sectionMinLimit } = getSectionLimit({
    index,
    sectionMarkerList: renderSectionMarker,
    playedSeconds,
    isLastSection,
  });
  viewCaptionList?.forEach((item) => {
    const targetTime = item.start / 1000;
    if (
      isMatchLimit({
        targetTime,
        sectionMaxLimit,
        sectionMinLimit,
        isLastSection,
      })
    ) {
      res.push(item);
    }
  });
  return res;
};

export const groupCaptionBySection = (
  renderSectionMarker,
  viewCaptionList,
  duration,
) => {
  if (viewCaptionList?.length) {
    return renderSectionMarker?.map((item, index) =>
      findSectionRelevantCaption(
        index,
        renderSectionMarker,
        viewCaptionList,
        duration,
      ),
    );
  }
  return [];
};

export const findSectionRelevantItem = (
  index,
  sectionMarkerList,
  itemList,
  playedSeconds,
) => {
  const res = [];

  const isLastSection = index === sectionMarkerList.length - 1;

  const { sectionMaxLimit, sectionMinLimit } = getSectionLimit({
    index,
    sectionMarkerList,
    playedSeconds,
    isLastSection,
  });

  itemList.forEach((item) => {
    const targetTime = formatToSeconds(item.markerTime);
    if (
      isMatchLimit({
        targetTime,
        sectionMaxLimit,
        sectionMinLimit,
        isLastSection,
      })
    ) {
      res.push({
        ...item,
        markerSecondsTime: formatToSeconds(item.markerTime),
      });
    }
  });
  const sortByMarkerTime = sortWith([ascend(prop("markerSecondsTime"))]);

  return sortByMarkerTime(res);
};

/**
 *
 * @param infoData: course info data
 * @param removeItem: remove chapter or content item
 * @returns filter result
 */
export const filterCourseInfoItem = (infoData, removeItem) => {
  const getResultChapter = (result) => result.courseContent.chapters;
  const filterChapter = (result, removeChapter) => {
    const { chapterId } = removeChapter || {};
    const contentChaptersInfo = result.courseContent.chapters;
    const removeChapterTarget = (n) => n.id !== chapterId;
    const filterChapterResult = filter(
      removeChapterTarget,
      contentChaptersInfo,
    );
    const temp = { ...result };
    temp.courseContent.chapters = filterChapterResult;
    return temp;
  };

  const filterContent = (result, removeContent) => {
    const temp = { ...result };
    const { chapterId: contentChapterId, id: contentId } = removeContent || {};
    const removeContentChapterTarget = (n) => n.id === contentChapterId;
    const contentChapterIndex = findIndex(propEq("id", contentChapterId))(
      getResultChapter(result),
    );
    const removeContentTarget = (n) => n.id !== contentId;
    const catchContentChapter = filter(
      removeContentChapterTarget,
      getResultChapter(result),
    )[0];
    const catchContent =
      catchContentChapter &&
      filter(removeContentTarget, catchContentChapter.items);
    if (catchContent?.length) {
      temp.courseContent.chapters[contentChapterIndex].items = catchContent;
    }
    return temp;
  };

  let result = infoData;

  if (removeItem?.itemType === "chapter") {
    result = filterChapter(result, removeItem);
  }

  if (removeItem?.itemType === "content") {
    result = filterContent(result, removeItem);
  }

  return result;
};

export const syncCourseData = (
  courseInfoData,
  prevCourseData = null,
  isRefreshPage = false,
) => {
  if (prevCourseData && !isRefreshPage) {
    return {
      ...courseInfoData?.courseInfo,
      ...courseInfoData?.courseContent, // 如果正在改chapter內的description會被reload覆蓋，因為考慮undo的情況，先不解決這個問題
      // prevCourseData 避免正在更新內容時reload導致尚未儲存的資料被覆蓋
      ...{ description: prevCourseData.description },
      ...{ title: prevCourseData.title },
      ...{ duration: prevCourseData.duration },
      courseCertificate: courseInfoData?.courseCertificate,
    };
  }
  return {
    ...courseInfoData?.courseInfo,
    ...courseInfoData?.courseContent,
    courseCertificate: courseInfoData?.courseCertificate,
  };
};

export const sectionMarkerPlayed = (videoInfoData) => {
  const res = [];
  if (videoInfoData.sectionMarkers) {
    let playedCount = 0;
    videoInfoData.sectionMarkers.forEach((item) => {
      playedCount += item.widthRatio;
      res.push(playedCount);
    });
  }
  return res;
};
