import clientAPI from "@utils/axios/client-api";
import axios from "axios";
import qs from "querystringify";
import { parse } from "subtitle";

export const getCaptionContent = async (url) => {
  try {
    const getData = await axios.get(url, {
      transformRequest: [
        (data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.common.Authorization;
          if (data) {
            return data;
          }
          return null;
        },
      ],
    });
    return { data: parse(getData.data) };
  } catch (error) {
    return error;
  }
};

const getNewCaptionList = async (captionData) => {
  try {
    const res = JSON.parse(JSON.stringify(captionData));
    captionData.data.captionList.forEach(async (item, index) => {
      const { data, error } = await getCaptionContent(item.captionUrl);
      if (error) throw error;
      // TODO: consider maybe one of captionData url download filed
      res.data.captionList[index].captionContent = data;
    });
    return res;
  } catch (error) {
    console.error(`getNewCaptionList`, error);
    return captionData;
  }
};

export const getCaptionList = async (videoId, type = "reference") => {
  try {
    const { data: resData, error } = await clientAPI.get(
      `/api/v1/studio/video/${videoId}/caption?type=${type}`,
    );
    if (error) throw error;
    return await getNewCaptionList(resData);
  } catch (error) {
    return error;
  }
};

export const postCaption = ({ videoId, data, type = "reference", config }) =>
  clientAPI
    .post(`/api/v1/studio/video/${videoId}/caption?type=${type}`, data, config)
    .then((response) => response.data);

export const putCaption = ({ videoId, data, type = "reference", config }) =>
  clientAPI
    .put(`/api/v1/studio/video/${videoId}/caption?type=${type}`, data, config)
    .then((response) => response.data);

/**
 * @api {delete} /api/v1/studio/video/${videoId}/caption
 * @apiDescription delete caption
 */

const deleteCaptionPoint = (props) => {
  const { videoId, language, type = "reference" } = props;
  const query = qs.stringify({
    type,
    language,
  });
  return clientAPI
    .delete(`/api/v1/studio/video/${videoId}/caption?${query}`)
    .then((response) => response.data);
};

/**
 * @api {put} api/v1/studio/video/caption/${videoId}
 * @apiDescription add assessment
 */

const setDefaultCaption = ({ videoId, language, type = "reference" }) =>
  clientAPI
    .put(
      `/api/v1/studio/video/caption/${videoId}?type=${type}&language=${language}`,
      {
        videoId,
        language,
      },
    )
    .then((response) => response.data);

export { deleteCaptionPoint, setDefaultCaption };
