import { currencyEnum } from "@constants/currencyEnum";
import numeral from "numeral";

const parseCurrency = ({ currency = 1, price }) => {
  if (currency === 0) {
    return "FREE";
  }
  return `${currencyEnum[currency]} ${numeral(price).format("0,0.[00]")}`;
};

export default parseCurrency;
