import { currencyEnum } from "@constants/currencyEnum";
import parseCurrency from "@utils/parse-currency";
import React from "react";

const currencyList = ["SGD", "USD", "TWD", "CNY", "EUR", "JPY"];

const feeTypeEnum = {
  free: 1,
  oneTimePayment: 2,
  bundlePrice: 3,
  1: "free",
  2: "oneTimePayment",
  3: "bundlePrice",
};

const getCurrencyText = (props) => {
  const { feeType, price, currency, intl } = props;
  switch (feeType) {
    case feeTypeEnum.oneTimePayment: {
      return parseCurrency({
        ...props,
        currency,
        price,
      });
    }
    case feeTypeEnum.bundlePrice:
      return (
        <span style={{ textDecorationLine: "line-through" }}>
          {parseCurrency({
            ...props,
            currency,
            price,
          })}
        </span>
      );
    case feeTypeEnum.free:
    default:
      return intl.formatMessage({
        id: "common.paymentType.free",
      });
  }
};

export { currencyEnum, currencyList, feeTypeEnum, getCurrencyText };
