import dayjs from "@utils/dayjs-utils";
import { useIntl } from "react-intl";

export const useFormatDuration = () => {
  const intl = useIntl();
  const { locale = "en" } = intl;
  return (durationString, unit = "minutes") => {
    const duration = parseInt(durationString, 10);
    if (!durationString || Number.isNaN(duration)) {
      return `0${
        unit === "seconds"
          ? intl.formatMessage({
              id: "common.duration.seconds",
              defaultMessage: "s",
            })
          : intl.formatMessage({
              id: "common.duration.minutes",
              defaultMessage: "m",
            })
      }`;
    }

    const hours = Math.floor(dayjs.duration(durationString, unit).asHours());
    const minutes = Math.floor(
      dayjs.duration(durationString, unit).get("minutes"),
    );
    const seconds = Math.floor(
      dayjs.duration(durationString, unit).get("seconds"),
    );
    const hoursString =
      hours > 0
        ? hours +
          intl.formatMessage({
            id: "common.duration.hours",
            defaultMessage: "h",
          })
        : "";
    const minutesString =
      minutes > 0
        ? minutes +
          intl.formatMessage({
            id: "common.duration.minutes",
            defaultMessage: "m",
          })
        : "";
    const secondsString =
      seconds > 0
        ? seconds +
          intl.formatMessage({
            id: "common.duration.seconds",
            defaultMessage: "s",
          })
        : "";
    return [hoursString, minutesString, secondsString]
      .join(locale === "en" ? " " : "")
      .trim();
  };
};
